/* eslint-disable camelcase */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form } from 'calidation';
import omit from 'lodash/omit';

import InfoAction from '@store/actions/information';
import CaptureAction from '@store/actions/capture';
import EditedAction from '@store/actions/edited';

import { toDocumentType } from '@lib/constants/documents';
import Page from '@lib/components/v2/Page';
import { ConfirmModal } from '@lib/components/v2/ConfirmModal';
import { isAgeEligible, selectEligibleAge } from '@js/domain/ageEligibility';
import { getAge, getCookie, setCookie } from '@lib/Utils';
import { localizedString } from '@languages';
import APIs from '@services/APIs';

import {
  isDocumentExpired,
  isEligibleForPassportExpiredLogic,
  isExpiredIdAllowed,
  isPassportExpiredBy2Years
} from '@lib/utils/checkExpiredDocument';
import { AgeReview } from '@lib/components/v2/AgeReview';
import {
  ADDITIONAL_RECAPTURE_ALLOWED,
  DISABLE_BACK_BUTTON_ON_EDIT_TOO_MANY,
  ENABLE_CONFIRM_AGE,
  FLOW_V2_EDIT_TOO_MANY_FIELDS_TRY_AGAIN_MODE,
  FLOW_V2_EXPIRED_ID_BY_2_YEARS_TRY_AGAIN_MODE,
  FLOW_V2_EXPIRED_ID_TRY_AGAIN_MODE,
  FLOW_V2_VERIFY_DETAILS_SHOW_CONSENT,
  ID_NUMBER_EDITABLE
} from '@spotMobileConfig';
import { Error500 } from '@lib/components/v2/errors';
import {
  ConfirmConsent,
  EditTooManyFields,
  ExpiredID,
  ExpiredIDBy2Years,
  IdNumberNotEditable,
  SelectAnotherIdException,
  TooManyRetryAttempts
} from '@lib/components/v2/VerifyDetails/VerifyDetails.errors';
import { shouldShowAddress } from '@lib/utils/v2/getFields/getFields';
import { ChooseAnotherID, VerifyDetailsContent } from '../../components/Contents';

const VerifyDetails = ({
  location,
  flowType,
  appConfig,
  verifyDetails,
  isFlowV2DiffId,
  setFrontIDParams,
  retake,
  setConfirmedInfo,
  onGoBack,
  onNextStep,
  eligibleAge,
  editedFields,
  idType,
  onChooseDiffId,
  isOldTurkishDL,
  lastConfirmedDetails,
  personNameFieldsOrder,
  useIdNumber,
  setEditedFields,
  disabledFields
}) => {
  const [data, setData] = useState(() =>
    lastConfirmedDetails ? { ...lastConfirmedDetails } : { ...verifyDetails }
  );
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasFormErrors, setHasFormErrors] = useState(false);
  const [showChooseAnotherId, setShowChooseAnotherId] = useState(false);
  const [isExitConfirmModalDisplayed, setIsExitConfirmModalDisplayed] = useState(false);

  const handleConfirm = async ({ isValid }) => {
    if (!isValid) return;

    const dataWithEdited = { ...data, edited: editedFields };
    const { dateOfBirth, expiryDate, cardType, countryCode, unitNumber, checkConfirm } =
      dataWithEdited;

    if (FLOW_V2_VERIFY_DETAILS_SHOW_CONSENT && !checkConfirm) {
      setError({
        component: ConfirmConsent,
        props: {
          buttons: [
            {
              label: localizedString('ok'),
              onClick: () => setError(null)
            }
          ]
        }
      });
      return;
    }

    const age = getAge(dateOfBirth, true);
    if (ENABLE_CONFIRM_AGE && !isAgeEligible(age, eligibleAge)) {
      setError({
        component: AgeReview,
        props: {
          eligibleAge,
          onTryAgain: () => setError(null)
        }
      });
      return;
    }

    const isPassportExpiredBy2YearsFlag = isPassportExpiredBy2Years(expiryDate, {
      appConfig,
      cardType,
      countryCode
    });
    const isDocumentExpiredFlag = isDocumentExpired(expiryDate);
    const isExpired = isEligibleForPassportExpiredLogic(appConfig, cardType, countryCode)
      ? isPassportExpiredBy2YearsFlag
      : isDocumentExpiredFlag;

    const recaptureButtons = [
      {
        label: localizedString('back'),
        variant: 'transparent',
        onClick: () => setError(null)
      },
      {
        label: localizedString('recaptureDocument'),
        onClick: () => {
          setFrontIDParams({});
          retake();
        }
      }
    ];

    const tryAgain = isPassportExpiredBy2YearsFlag
      ? FLOW_V2_EXPIRED_ID_BY_2_YEARS_TRY_AGAIN_MODE
      : FLOW_V2_EXPIRED_ID_TRY_AGAIN_MODE;

    const tryAgainButtons = [
      {
        label: localizedString('tryAgain'),
        onClick: () => {
          setFrontIDParams({});
          retake();
        }
      }
    ];

    if (isExpired && !isExpiredIdAllowed({ flowType, cardType })) {
      setError({
        component: isPassportExpiredBy2YearsFlag ? ExpiredIDBy2Years : ExpiredID,
        props: { buttons: tryAgain ? tryAgainButtons : recaptureButtons }
      });
      return;
    }

    setLoading(true);

    if (unitNumber) {
      dataWithEdited.addressData.unit_door_full_number = unitNumber;
      dataWithEdited.addressData.unit_door_value = unitNumber;
      dataWithEdited.addressData.homeAddress += ` ${unitNumber}`;
      dataWithEdited.addressData.fullAddress += ` ${unitNumber}`;
      dataWithEdited.addressData.addressLine2 += ` ${unitNumber}`;
    }

    const confirmedData = omit({ ...dataWithEdited }, ['unitNumber']);
    setConfirmedInfo(confirmedData);

    const params = {
      ...confirmedData,
      location,
      flowType,
      countryCode: verifyDetails.countryCode
    };
    if (!shouldShowAddress({ isFlowV2: true, address: data.address })) {
      params.validateAddress = false;
    }
    APIs.store(params, isFlowV2DiffId)
      .then(
        ({
          status,
          type,
          msg,
          dataCheckOnConfirm = false,
          poaEnabled = false,
          chooseDiffId = false
        }) => {
          setLoading(false);
          if (status !== 'success') {
            if (msg === 'Too many fields edited') {
              showTooManyFieldsEditedError();
              return;
            }

            setError({
              component: Error500,
              props: {
                issue: msg,
                buttons: [
                  {
                    label: localizedString('cancel'),
                    onClick: () => {
                      if (type === 'cards') {
                        onGoBack();
                      } else {
                        setError(null);
                      }
                    }
                  }
                ]
              }
            });
            return;
          }

          if (chooseDiffId) {
            setError({
              component: SelectAnotherIdException,
              props: {
                buttons: [
                  {
                    label: localizedString(
                      'dataCheck.FLOW_V2_DATA_CHECK_SELECT_DIFF_ID_BUTTON_TEXT'
                    ),
                    onClick: () => {
                      setShowChooseAnotherId(true);
                      setError(null);
                    }
                  }
                ]
              }
            });
            return;
          }

          onNextStep({ dataCheckOnConfirm, poaEnabled });
        }
      )
      .catch(({ message }) => {
        console.error(message);
        const error = {
          component: Error500,
          props: { onTryAgain: () => retake() }
        };
        setError(error);
      });
  };

  async function handleChange(id, value) {
    if (!ID_NUMBER_EDITABLE && ['idNumber', 'licenceNumber', 'passportNumber'].includes(id)) {
      showNotEditableIdNumberError();
      return;
    }

    setData((data) => {
      let dataToUpdate = {
        ...data,
        [id]: value
      };
      if (id === 'checkConfirm') {
        dataToUpdate = {
          ...dataToUpdate,
          dataCheckConsentAt: new Date().toISOString().replace('T', ' ').split('.')[0]
        };
      }
      if (['licenceNumber', 'passportNumber'].includes(id) && dataToUpdate.idNumber) {
        dataToUpdate.idNumber = value;
      }

      return dataToUpdate;
    });
  }

  const handleFormUpdate = ({ isValid, errors, setError, fields = {}, setField }) => {
    setHasFormErrors(!isValid);

    if (errors.tooManyFieldsEdited) {
      showTooManyFieldsEditedError();

      // Just reset the form with previous value
      const ignoreFields = [
        'addressData',
        'homeAddress',
        'addressLine1',
        'addressLine2',
        'suburb',
        'postcode',
        'state_territory',
        'tooManyFieldsEdited',
        'isMatch'
      ];
      for (const field in fields) {
        if (!ignoreFields.includes(field)) {
          setField({ [field]: data[field] });
        }
      }
      setError({ tooManyFieldsEdited: null });
    }
  };

  function showTooManyFieldsEditedError() {
    let editTooManyFieldsButtons;
    if (FLOW_V2_EDIT_TOO_MANY_FIELDS_TRY_AGAIN_MODE) {
      editTooManyFieldsButtons = [
        {
          label: localizedString('tryAgain'),
          onClick: () => {
            onRecaptureClick();
          }
        }
      ];
    } else {
      editTooManyFieldsButtons = [
        {
          label: localizedString('back'),
          variant: 'transparent',
          onClick: () => setError(null)
        },
        {
          label: localizedString('recapture'),
          onClick: () => {
            onRecaptureClick();
          }
        }
      ];

      if (DISABLE_BACK_BUTTON_ON_EDIT_TOO_MANY) {
        editTooManyFieldsButtons.shift();
      }
    }

    setError({
      component: EditTooManyFields,
      props: { buttons: editTooManyFieldsButtons }
    });
  }

  function showNotEditableIdNumberError() {
    document.activeElement.blur(); // hide keyboard
    setError({
      component: IdNumberNotEditable,
      props: {
        buttons: [
          {
            label: localizedString('back'),
            variant: 'outline',
            onClick: () => setError(null)
          },
          {
            label: localizedString('recapture'),
            onClick: () => {
              onRecaptureClick();
            }
          }
        ]
      }
    });
  }

  function onRecaptureClick() {
    const attempts = parseInt(getCookie('idCaptureAttempt'), 10) || 1;

    setEditedFields([]);
    if (ADDITIONAL_RECAPTURE_ALLOWED && attempts > ADDITIONAL_RECAPTURE_ALLOWED) {
      setError({
        component: TooManyRetryAttempts,
        props: {
          buttons: [
            {
              label: localizedString('back'),
              large: true,
              shadow: true,
              onClick: () => {
                setCookie('idCaptureAttempt', 0, -7);
                setError(null);
              }
            }
          ]
        }
      });
      return;
    }
    setFrontIDParams({});
    retake();
  }

  const { component: Error, props: errorProps } = error || {};

  const footerButtons = [
    {
      label: localizedString('back'),
      variant: 'transparent',
      onClick: () => setIsExitConfirmModalDisplayed(true),
      dataTestId: 'details-back'
    },
    {
      label: loading
        ? localizedString('loading')
        : localizedString('verifyDetails.FLOW_V2_VERIFY_DETAILS_ACTION_BUTTON'),
      type: 'submit',
      disabled:
        (FLOW_V2_VERIFY_DETAILS_SHOW_CONSENT && !data.checkConfirm) || hasFormErrors || loading,
      variant: loading ? 'transparent' : 'secandary',
      loading,
      dataTestId: 'details-confirm'
    }
  ];

  const currentDoc = {
    type: toDocumentType(data.cardType)
  };

  if (showChooseAnotherId) {
    return <ChooseAnotherID currentDoc={currentDoc} onNextStep={(doc) => onChooseDiffId(doc)} />;
  }

  if (Error) {
    return <Error {...errorProps} />;
  }

  return (
    <div>
      {isExitConfirmModalDisplayed && (
        <ConfirmModal
          heading={localizedString('app.FLOW_V2_EXIT_SCREEN_TITLE')}
          onConfirm={() => onGoBack()}
          onCancel={() => setIsExitConfirmModalDisplayed(false)}
        >
          {localizedString('app.FLOW_V2_EXIT_SCREEN_DESCRIPTION_DETAILS')}
        </ConfirmModal>
      )}

      <Form onUpdate={handleFormUpdate} onSubmit={handleConfirm}>
        <Page buttons={footerButtons} footerShadow>
          <VerifyDetailsContent
            {...data}
            engine4Config={appConfig.engine4 ? appConfig.engine4.FLOW_V2 : null}
            idType={idType}
            cardType={data.cardType}
            useIdNumber={useIdNumber}
            isOldTurkishDL={isOldTurkishDL}
            country={verifyDetails.countryCode}
            countryOfIssue={verifyDetails.countryOfIssue}
            onChange={(id, value) => handleChange(id, value)}
            isSubmiting={loading}
            personNameFieldsOrder={personNameFieldsOrder}
            disabledFields={disabledFields}
          />
        </Page>
      </Form>
    </div>
  );
};

VerifyDetails.propTypes = {
  onNextStep: PropTypes.func,
  retake: PropTypes.func,
  setFrontIDParams: PropTypes.func,
  idType: PropTypes.string,
  location: PropTypes.string,
  verify: PropTypes.bool,
  flowType: PropTypes.string,
  appConfig: PropTypes.object,
  verifyDetails: PropTypes.object,
  isFlowV2DiffId: PropTypes.bool,
  setConfirmedInfo: PropTypes.func,
  onGoBack: PropTypes.func,
  setEditedFields: PropTypes.func,
  onChooseDiffId: PropTypes.func,
  isOldTurkishDL: PropTypes.bool,
  eligibleAge: PropTypes.number,
  extracted: PropTypes.object,
  lastConfirmedDetails: PropTypes.object,
  editedFields: PropTypes.array,
  personNameFieldsOrder: PropTypes.arrayOf(PropTypes.string),
  useIdNumber: PropTypes.bool,
  disabledFields: PropTypes.array
};

VerifyDetails.defaultProps = {
  onNextStep: () => null,
  onGoBack: () => null,
  retake: () => null,
  useIdNumber: false,
  idType: 'NZL_DRIVERLICENCE',
  verify: false,
  verifyDetails: {}
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyDetails);

/**
 * Map the store's state to the component's props
 * @param  {Object} state
 * @return {Object}
 */
function mapStateToProps(state) {
  const { information, capture, appConfig, edited } = state;
  return {
    extracted: information.extracted,
    lastConfirmedDetails: information.lastConfirmedDetails,
    frontParams: capture.frontParams,
    editedFields: edited.fields,
    appConfig,
    eligibleAge: selectEligibleAge(state)
  };
}

/**
 * Map the dispatch function of the store to the component's props
 * @param  {Function} dispatch The dispatch function
 * @return {Object}
 */
function mapDispatchToProps(dispatch) {
  return {
    setConfirmedInfo: (data) => dispatch(InfoAction.setConfirmedInfo(data)),
    setFrontIDParams: (data) => dispatch(CaptureAction.setFrontIDParams(data)),
    setEditedFields: (data) => dispatch(EditedAction.setEditedFields(data))
  };
}
