import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { FaceVideoCapture, LIVENESS_STATUS, useLivenessState } from 'liveness-ui';
import APIs from '@services/APIs';
import { Error500 } from '@lib/components/v2/errors';
import { useLivenessSession } from '@js/hooks/useLivenessSession';
import { LoadingSpinner } from '@FLOW_V2_FLOW/components';
import { getLanguage, localizedString } from '@languages';
import { getCookie, setCookie } from '@lib/Utils';
import { Prepare } from '@js/lib/components/v2/Contents/FaceScan';
import {
  CameraSettings,
  Failed,
  Incompatible,
  Permission,
  PermissionIssueRequiresAlternativeFlow
} from './FaceScan.errors';

const STEP = {
  TIPS: 'TIPS',
  CAPTURING_SELFIE_VIDEO: 'CAPTURING_SELFIE_VIDEO',
  CAPTURE_COMPLETED: 'CAPTURE_COMPLETED',
  CAPTURE_RETRY: 'CAPTURE_RETRY',
  RETRY_CAM_PERMISSION_ERROR: 'CAPTURE_RETRY_CAM_PERMISSION_ERROR',
  RETRY_CHECK_CAMERA_SETTINGS_ERROR: 'RETRY_CHECK_CAMERA_SETTINGS_ERROR',
  REQUIRES_ALTERNATIVE_FLOW_ERROR: 'REQUIRES_ALTERNATIVE_FLOW_ERROR',
  INCOMPATIBLE_DEVICE_ERROR: 'INCOMPATIBLE_DEVICE_ERROR',
  UNEXPECTED_ERROR: 'UNEXPECTED_ERROR',
  SESSION_EXPIRED: 'SESSION_EXPIRED'
};

export function FaceScan({ onComplete, onSelfie, onGoBack }) {
  const appConfig = useSelector((state) => state.appConfig);
  const [step, setStep] = useState(STEP.TIPS);
  const {
    sessionId,
    txnId,
    fetchLivenessSession,
    errorMessage: errorCreatingLivenessTokens,
    isLoading: isFetchingLivenessTokens
  } = useLivenessSession();
  const { livenessStatus, setAsComplete } = useLivenessState();
  const [audioRepository, setAudioRepository] = useState();

  const currentLanguageCode = getLanguage();

  useEffect(() => {
    APIs.status('faceScanTips');
  }, []);

  useEffect(() => {
    switch (livenessStatus) {
      case LIVENESS_STATUS.SESSION_EXPIRED_ERROR:
        setStep(STEP.SESSION_EXPIRED);
        break;
      case LIVENESS_STATUS.INCOMPATIBLE_DEVICE_ERROR:
        setStep(STEP.INCOMPATIBLE_DEVICE_ERROR);
        break;
      case LIVENESS_STATUS.DETECTING_ERROR: {
        APIs.status('livenessFail');

        let retryFaceCaptureCount = parseInt(getCookie('retryAttempt'), 10) || 0;

        if (retryFaceCaptureCount) {
          setAsComplete();
        } else {
          APIs.uploadVideo({ id: sessionId, attempt: 1, actions: `Smile` }, null);
          setStep(STEP.CAPTURE_RETRY);
        }

        ++retryFaceCaptureCount;
        setCookie('retryAttempt', retryFaceCaptureCount.toString(), 1 / 24);

        break;
      }
      case LIVENESS_STATUS.CAM_PERMISSION_ERROR: {
        let retryEnableCameraCount = parseInt(getCookie('retry'), 10) || 0;

        if (retryEnableCameraCount === 0) {
          setStep(STEP.RETRY_CAM_PERMISSION_ERROR);
        } else if (retryEnableCameraCount === 1) {
          setStep(STEP.RETRY_CHECK_CAMERA_SETTINGS_ERROR);
        } else {
          setStep(STEP.REQUIRES_ALTERNATIVE_FLOW_ERROR);
        }

        ++retryEnableCameraCount;
        setCookie('retry', retryEnableCameraCount.toString(), 1 / 24);

        break;
      }
      case LIVENESS_STATUS.UNEXPECTED_ERROR:
        setStep(STEP.UNEXPECTED_ERROR);
        break;
      case LIVENESS_STATUS.CLOSED:
        onComplete({ sessionId });
        break;
      case LIVENESS_STATUS.IDDLE:
        break;
      default:
        // eslint-disable-next-line no-console
        console.info('Liveness status not handled:', { livenessStatus });
    }
  }, [livenessStatus]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setAudioRepository({
        positionYourFace: new Audio(`/media/audio/${currentLanguageCode}/position-your-face.mp3`),
        smile: new Audio(`/media/audio/${currentLanguageCode}/smile.mp3`),
        biggerSmile: new Audio(`/media/audio/${currentLanguageCode}/bigger-smile.mp3`),
        thanks: new Audio(`/media/audio/${currentLanguageCode}/thanks.mp3`)
      });
    });

    return () => {
      clearTimeout(timeoutId);
    };
  }, [currentLanguageCode]);

  const handleStart = () => {
    APIs.status('faceCapture');
    setStep(STEP.CAPTURING_SELFIE_VIDEO);
  };

  const retryButtons = [
    {
      label: localizedString('back'),
      variant: 'transparent',
      onClick: () => setStep(STEP.CAPTURING_SELFIE_VIDEO)
    },
    {
      label: localizedString('tryAgain'),
      onClick: () => setStep(STEP.CAPTURING_SELFIE_VIDEO),
      dataTestId: 'btn-try-again'
    }
  ];

  const retryFetchingLivenessTokensButtons = [
    {
      label: localizedString('tryAgain'),
      onClick: () => {
        fetchLivenessSession();
        setStep(STEP.CAPTURING_SELFIE_VIDEO);
      },
      dataTestId: 'btn-try-again'
    }
  ];

  if (step === STEP.CAPTURING_SELFIE_VIDEO) {
    if (isFetchingLivenessTokens) {
      return <LoadingSpinner subtitle="" heading={localizedString('verifyingYourIdentity')} />;
    }

    if (errorCreatingLivenessTokens) {
      return <Error500 buttons={retryFetchingLivenessTokensButtons} />;
    }

    if (!sessionId || !txnId || !audioRepository) {
      return <LoadingSpinner subtitle="" heading={localizedString('verifyingYourIdentity')} />;
    }

    return (
      <FaceVideoCapture
        livenessWSSUrl={appConfig.WSS_URL}
        sessionId={sessionId}
        txnId={txnId}
        audioRepository={audioRepository}
        wording={{
          positionFacePromptTitle: localizedString('positionFaceInFrameNewLine'),
          giveSmilePromptTitle: localizedString('giveABigSmile'),
          giveBiggerSmilePromptTitle: localizedString('giveABiggerSmile'),
          tapAllowToEnableCamera: localizedString('toContinueTapAllow'),
          loading: localizedString('loading'),
          thanks: localizedString('thanks')
        }}
      />
    );
  }

  return (
    <>
      {step === STEP.TIPS && <Prepare onGoBack={onGoBack} onStart={handleStart} />}
      {step === STEP.SESSION_EXPIRED && <Error500 buttons={retryFetchingLivenessTokensButtons} />}
      {step === STEP.RETRY_CHECK_CAMERA_SETTINGS_ERROR && <CameraSettings buttons={retryButtons} />}
      {step === STEP.RETRY_CAM_PERMISSION_ERROR && <Permission buttons={retryButtons} />}
      {step === STEP.INCOMPATIBLE_DEVICE_ERROR && <Incompatible />}
      {step === STEP.CAPTURE_RETRY && <Failed buttons={retryButtons} />}
      {step === STEP.REQUIRES_ALTERNATIVE_FLOW_ERROR && (
        <PermissionIssueRequiresAlternativeFlow
          buttons={[
            {
              label: localizedString('back'),
              variant: 'transparent',
              onClick: () => document.location.reload()
            },
            {
              label: localizedString('faceVerification.FLOW_V2_TRY_SOMETHING_ELSE_ACTION_BUTTON'),
              onClick: onSelfie
            }
          ]}
        />
      )}
      {step === STEP.UNEXPECTED_ERROR && (
        <Error500 buttons={[{ ...retryButtons[1], shadow: true }]} />
      )}
    </>
  );
}

FaceScan.propTypes = {
  onGoBack: PropTypes.func,
  onSelfie: PropTypes.func,
  onComplete: PropTypes.func
};
