import React, { useEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';

import InfoAction from '@store/actions/information';
import EditedAction from '@store/actions/edited';
import CaptureAction from '@store/actions/capture';

import { getCookie, isShownPrivacy, setCookie } from '@lib/Utils';
import APIs from '@services/APIs';
import { localizedString } from '@languages';

import { waitUntilLastCardStorageIsReady } from '@FLOW_V2_FLOW/services/storageCheckAPI';
import { DigitalDLTimeLimitError } from '@FLOW_V2_FLOW/components/ErrorScreens/DigitalDLTimeLimitError';
import { Loading } from '@FLOW_V2_FLOW/components/Contents/IdSelection/Loading';
import { LoadingSpinner } from '@FLOW_V2_FLOW/components';
import { IdSelectionContent } from '@FLOW_V2_FLOW/components/Contents';

import { withUseFullNameContextHook } from '@lib/components/v2/App/FullNameReviewProvider';
import { getTransactionToken } from '@lib/utils/transactionToken';
import { isTimeLimitOKForDigitalScreenShot } from '@lib/utils/digitalDLCheck';
import { BackOfCard, CaptureTips } from '@lib/components/v2/Contents';
import {
  CouldNotRecognise,
  DocNotAccepted,
  PoorQuality,
  Recapture
} from '@lib/components/v2/VerifyDetails/VerifyDetails.errors';
import { withGeolocation } from '@lib/components/v2/Capture/Geolocation';
import { withCameraPermission } from '@lib/components/v2/Capture/CameraPermission';
import { formatDay } from '@lib/utils/date';
import { CaptureNSWDigitalDriverLicenceScreen } from '@lib/pages/v2/CaptureNSWDigitalDriverLicenceScreen';
import Page from '@lib/components/v2/Page';

import {
  ENABLE_VISA_AFTER_PASSPORT,
  FLOW_V2_DOC_NOT_ACCEPTED_TRY_AGAIN_MODE,
  HIDE_FOOTER_UPLOAD_SPINNER
} from '@spotMobileConfig';

import { Error500 } from '@js/lib/components/v2/errors';
import { isValidImageFile } from '@js/lib/validations/file';
import { CaptureBase, CapturePage } from './CaptureBase';
import { CaptureSideOfCard } from './CaptureSideOfCard';
import { CaptureVisa } from './CaptureVisa';
import { AcceptableDocs } from './AcceptableDocs';
import { STEPS, useStep } from './hooks';

const Capture = ({
  onNextStep,
  onGoBack,
  flowType,
  frontParams,
  selectedDiffId,
  isFlowV2DiffId,
  captureVisa = false,
  resetEditedFields,
  resetExtractedInfo,
  resetConfirmedInfo,
  setFrontIDParams,
  verify,
  setDocId,
  onSelectLanguage,
  engine4Config,
  ipCountryCode,
  setExtractedInfo,
  setShouldDisplayFullName,
  isCameraEnabled,
  checkCameraPermission
}) => {
  const [idType, setIdType] = useState('AUS_AUTO_DRIVERLICENCE');

  const [progressBar, setProgressBar] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);

  const [error, setError] = useState(null);
  const [acceptableDocs, setAcceptableDocs] = useState(false);
  const [showCaptureTips, setShowCaptureTips] = useState('');

  const [showCaptureDigitalIdScreen, setShowCaptureDigitalIdScreen] = useState(false);

  const [verifyDetails, setVerifyDetails] = useState(false);
  const [extractedData, setExtractedData] = useState(null);

  const { currentStep, getNextStep, goToNextStep, goToStep, updateStep } = useStep({
    captureVisa
  });
  const backOfCard = currentStep === STEPS.BACK;

  const elementRef = useRef();

  const updateStatusSelectId = (resetTables) => {
    APIs.status('selectId', { resetTables })
      .then(({ status }) => {
        if (status === 'failed') {
          throw new Error();
        }
      })
      .catch(() => {
        if (resetTables === 'yes') {
          const error = {
            component: Error500
          };
          setError({ error });
        }
      });
  };

  useEffect(() => {
    resetExtractedInfo();
    resetConfirmedInfo();
    resetEditedFields();

    if (captureVisa) {
      goToStep(STEPS.VISA);
      setIdType(selectedDiffId?.type);
    }

    if (!isFlowV2DiffId) {
      updateStatusSelectId(captureVisa ? 'no' : 'yes');
    }
  }, []);

  useEffect(() => {
    // return early for side of card because processExtractedData is called manually in handleStepAfterCaptureSideCard
    if (currentStep === STEPS.SIDE || getNextStep()?.name === STEPS.SIDE || !extractedData) return;
    processExtractedData(extractedData);
  }, [extractedData, currentStep]);

  function handleNextStep() {
    if (!idType) return;
    elementRef.current?.click();
  }

  function handleGoBack(e) {
    e?.preventDefault();
    const { backurl } = document.body.dataset;
    if (backurl && !isShownPrivacy('FLOW_V2')) {
      window.location.href = backurl;
    } else {
      onGoBack();
    }
  }

  const onClickedFooterTakePhoto = () => {
    checkCameraPermission({ withError: true });
    if (!isCameraEnabled) return;

    if (backOfCard) {
      elementRef.current?.click();
    } else {
      handleNextStep();
    }
  };

  const handleStepAfterCaptureSideCard = () => {
    const nextStep = getNextStep();
    if (!nextStep) {
      if (verify) APIs.status('checkDocument');
      processExtractedData({
        ...extractedData,
        skipPreparingCardsUserUpdate: true
      });
    } else {
      goToNextStep();
    }
  };

  const uploadImage = async (imagefile, idType) => {
    APIs.status(isFlowV2DiffId ? 'capturingOtherId' : 'capturingId');

    let idCaptureAttempt = parseInt(getCookie('idCaptureAttempt'), 10);
    idCaptureAttempt = idCaptureAttempt ? idCaptureAttempt + 1 : 1;
    setCookie('idCaptureAttempt', idCaptureAttempt.toString(), 1);

    let params;
    if (backOfCard) {
      params = { ...frontParams, backFile: imagefile };
    } else {
      params = { frontFile: imagefile };
      setFrontIDParams(params);
    }
    params.flowType = flowType;
    params.isDiffId = isFlowV2DiffId && !!selectedDiffId;

    try {
      setIsUploading(true);
      setAcceptableDocs(false);
      setError(null);
      setProgressBar(0);
      const { status, token, msg } = await APIs.uploadImage(params, {
        onProgress: (width) => setProgressBar(width)
      });

      setIsUploading(false);
      if (status === 'error') throw new Error(msg);
      if (!token) return;

      const otherParams = {
        isEngineV4: true,
        isDiffId: isFlowV2DiffId && !!selectedDiffId,
        flowType
      };
      if (idType) otherParams.idType = idType;
      else if (selectedDiffId?.type) otherParams.idType = selectedDiffId.type;

      setIsLoading(true);
      const {
        data: cardData,
        code,
        poorQuality = null,
        docAccepted = true,
        captureBackCard = false,
        captureSideCard = false,
        useIdNumber = false,
        isOldTurkishDL = false,
        poll = false,
        disableVerifyDetailsScreen = false,
        disabledVerifyDetailsFields,
        disabledFields,
        personNameFieldsOrder,
        displayFullName = false
      } = await APIs.extractIdentifyInfo(token, !backOfCard, otherParams);

      setShouldDisplayFullName?.(displayFullName);

      if (poll) {
        const transactionToken = getTransactionToken();
        await waitUntilLastCardStorageIsReady({ transactionToken });
      }

      setIsLoading(false);

      if (!isTimeLimitOKForDigitalScreenShot(cardData)) {
        setError({
          component: DigitalDLTimeLimitError,
          props: {
            buttons: [
              {
                label: localizedString('tryAgain'),
                onClick: () => {
                  setError(null);
                }
              }
            ]
          }
        });
        return;
      }

      const backButton = {
        label: localizedString('back'),
        variant: 'transparent',
        onClick: () => {
          setFrontIDParams({});
          setError(null);
          updateStatusSelectId('yes');
        },
        dataTestId: 'capture-backBtn'
      };

      if (docAccepted === false) {
        let buttons;
        if (FLOW_V2_DOC_NOT_ACCEPTED_TRY_AGAIN_MODE) {
          buttons = [
            {
              label: localizedString('tryAgain'),
              onClick: () => {
                setFrontIDParams({});
                setError(null);
              }
            }
          ];
        } else {
          buttons = [
            backButton,
            {
              label: localizedString('viewAcceptedIDs'),
              onClick: () => {
                setFrontIDParams({});
                setError(null);
                setAcceptableDocs(true);
              }
            }
          ];
        }

        setError({
          component: DocNotAccepted,
          props: { buttons }
        });
        return;
      }

      const viewTipsButtonLabel = localizedString('capture.FLOW_V2_VIEW_TIPS_BUTTON');

      if (code === '223') {
        setError({
          component: CouldNotRecognise,
          props: {
            buttons: [
              backButton,
              {
                label: localizedString('tryAgain'),
                onClick: () => {
                  setFrontIDParams({});
                  setError(null);
                }
              }
            ]
          }
        });
        return;
      }

      if (code === '220' || code === '230' || code === '240' || code === '270' || code !== '200') {
        setError({
          component: CouldNotRecognise,
          props: {
            buttons: [
              backButton,
              {
                label: viewTipsButtonLabel,
                onClick: () => {
                  setShowCaptureTips('CouldntRecognise');
                  setFrontIDParams({});
                  setError(null);
                }
              }
            ]
          }
        });
        return;
      }

      if (poorQuality) {
        setError({
          component: PoorQuality,
          props: {
            buttons: [
              {
                label: localizedString('back'),
                variant: 'transparent',
                onClick: () => {
                  if (!backOfCard) {
                    setFrontIDParams({});
                  }
                  setError(null);
                  updateStatusSelectId('no');
                },
                dataTestId: 'capture-backBtn'
              },
              {
                label: viewTipsButtonLabel,
                onClick: () => {
                  setShowCaptureTips('PoorQuality');
                  if (!backOfCard) {
                    setFrontIDParams({});
                  }
                  setError(null);
                },
                dataTestId: 'betterImg-viewTipsBtn'
              }
            ],
            cropped: !poorQuality || poorQuality === 'NULL' ? imagefile : poorQuality
          }
        });
        return;
      }

      if (backOfCard) {
        setFrontIDParams({});
      }

      if (!params.backFile && captureBackCard) {
        const country = cardData.countryCode;
        if (cardData.cardType.match(/LICENCE/i) && country === 'UK') {
          // Skip
        } else {
          setIdType(cardData.cardType);
          if (captureSideCard) updateStep(STEPS.SIDE, true);
          updateStep(STEPS.BACK, true);
          goToNextStep();
          return;
        }
      }

      if (captureSideCard) {
        updateStep(STEPS.SIDE, true);
        goToNextStep();
      }

      setExtractedData({
        cardData,
        useIdNumber,
        isOldTurkishDL,
        disableVerifyDetailsScreen,
        disabledVerifyDetailsFields,
        disabledFields,
        personNameFieldsOrder,
        token
      });
    } catch (e) {
      console.error(e);
      setError({
        component: CouldNotRecognise,
        props: {
          buttons: [
            {
              label: localizedString('tryAgain'),
              onClick: () => {
                setFrontIDParams({});
                setError(null);
                setIsUploading(false);
                setIsLoading(false);
                setIsWaiting(false);
                goToStep(STEPS.FRONT);
              }
            }
          ]
        }
      });
    }
  };

  async function processExtractedData({
    cardData,
    useIdNumber,
    isOldTurkishDL,
    disableVerifyDetailsScreen,
    disabledVerifyDetailsFields,
    disabledFields,
    personNameFieldsOrder,
    token,
    skipPreparingCardsUserUpdate = false
  }) {
    setIsLoading(true);

    const country = cardData.countryCode;

    const temp = cardData;
    temp.dateOfBirth = formatDay(temp.dateOfBirth);
    temp.expiryDate = formatDay(temp.expiryDate);

    temp.countryOfIssue = cardData.country;
    setDocId(temp.documentId);

    if (
      (cardData.cardType === 'Passport' || cardData.cardType === 'MRZ') &&
      ENABLE_VISA_AFTER_PASSPORT &&
      country &&
      country.toUpperCase() !== 'NZ' &&
      country.toUpperCase() !== 'AU'
    ) {
      setExtractedInfo(temp);
      let verifyDetails = { ...temp };
      if (cardData.givenNames) {
        const [firstName, middleName] = cardData.givenNames.split(' ');
        verifyDetails = { ...verifyDetails, firstName, middleName };
      }
      setIdType('PASSPORT');
      setIsLoading(false);
      goToStep(STEPS.VISA);
      setVerifyDetails(verifyDetails);
      return;
    }

    if (verify) {
      setIsLoading(false);
      setIsWaiting(true);

      const promise = skipPreparingCardsUserUpdate
        ? Promise.resolve()
        : APIs.status('preparingCards');

      await promise;
      const status = await APIs.checkApproval();

      setIsWaiting(false);
      if (status !== 'approved') {
        setError({
          component: Recapture,
          props: {
            buttons: [
              {
                label: localizedString('recapture'),
                onClick: () => {
                  setFrontIDParams({});
                  handleNextStep();
                }
              }
            ]
          }
        });
        setIsLoading(false);
        return;
      }
    }

    if (!disableVerifyDetailsScreen) {
      await APIs.status(isFlowV2DiffId ? 'reviewOtherInfo' : 'reviewInfo');
    }

    setExtractedInfo(temp);
    onNextStep({
      tokenId: token,
      idType: temp.cardType,
      backOfCard,
      verifyDetails: temp,
      useIdNumber,
      personNameFieldsOrder,
      isOldTurkishDL,
      captureVisa: false,
      disableVerifyDetailsScreen,
      disabledVerifyDetailsFields,
      disabledFields
    });
  }

  const { component: ErrorComponent, props: errorProps } = error || {};

  if (ErrorComponent) {
    return <ErrorComponent {...errorProps} />;
  }

  if (showCaptureTips !== '') {
    return (
      <CaptureTips
        type={showCaptureTips}
        onHide={() => setShowCaptureTips('')}
        onCaptureAgain={() => {
          setShowCaptureTips('');
          onClickedFooterTakePhoto();
        }}
        resetTables={
          isFlowV2DiffId || captureVisa || showCaptureTips === 'PoorQuality' ? 'no' : 'yes'
        }
      />
    );
  }

  if (acceptableDocs) {
    return (
      <CaptureBase uploadImage={uploadImage}>
        {({ capture }) => (
          <AcceptableDocs
            engine4Config={engine4Config}
            ipCountryCode={ipCountryCode}
            onBack={(e) => {
              e?.preventDefault();
              setAcceptableDocs(false);
            }}
            onClickedFooterTakePhoto={() => {
              if (idType) capture();
            }}
          />
        )}
      </CaptureBase>
    );
  }

  if (currentStep === STEPS.VISA) {
    return (
      <CaptureVisa
        idType={idType}
        onSkip={() => {
          setFrontIDParams({});
          onNextStep({ verifyDetails });
        }}
        setFrontIDParams={setFrontIDParams}
        onNextStep={() => onNextStep({ verifyDetails })}
        onGoBack={onGoBack}
        flowType={flowType}
        verifyDetails={verifyDetails}
      />
    );
  }

  if (isUploading || isLoading) {
    return <Loading progressBar={progressBar} isUploading={isUploading} />;
  }

  if (currentStep === STEPS.SIDE) {
    return (
      <CaptureSideOfCard
        onBack={() => goToStep(STEPS.FRONT)}
        onNext={() => handleStepAfterCaptureSideCard()}
      />
    );
  }

  if (backOfCard) {
    return (
      <CapturePage
        onBack={() => {
          goToStep(STEPS.FRONT);
          updateStatusSelectId('yes');
        }}
        uploadImage={uploadImage}
      >
        <BackOfCard idType={idType} />
      </CapturePage>
    );
  }

  function handleCapture(e) {
    const imageFile = e.target.files[0];
    if (!isValidImageFile(imageFile)) {
      setError({
        component: DocNotAccepted,
        props: {
          buttons: [
            {
              label: localizedString('tryAgain'),
              onClick: () => {
                setFrontIDParams({});
                setError(null);
              }
            }
          ]
        }
      });
      return;
    }

    e.target.value = '';
    uploadImage(imageFile);
  }

  const { backurl } = document.body.dataset;

  const footerButtons = [];
  if (isUploading || isLoading || isWaiting || isCameraEnabled === undefined) {
    footerButtons.push({
      label: '',
      variant: 'transparent',
      dataTestId: 'cptrId-back',
      'aria-hidden': true
    });

    if (!HIDE_FOOTER_UPLOAD_SPINNER) {
      footerButtons.push({
        label:
          isUploading && progressBar < 100
            ? localizedString('uploading')
            : localizedString('loading'),
        variant: 'transparent',
        loading: true,
        dataTestId: 'id-Uploading'
      });
    }
  } else {
    if (backurl || isShownPrivacy('FLOW_V2')) {
      footerButtons.push({
        label: localizedString('back'),
        variant: 'transparent',
        onClick: handleGoBack,
        dataTestId: 'cptrId-back'
      });
    }

    footerButtons.push({
      label: localizedString('captureMyID'),
      type: 'submit',
      onClick: () => onClickedFooterTakePhoto(),
      dataTestId: 'cptrId-btn'
    });
  }

  if (showCaptureDigitalIdScreen) {
    return (
      <CaptureNSWDigitalDriverLicenceScreen
        onUploadDigitalImage={({ imageFile, idType }) => uploadImage(imageFile, idType)}
        onCloseScreen={() => setShowCaptureDigitalIdScreen(false)}
      />
    );
  }

  return (
    <div>
      <input
        type="file"
        name="image"
        accept="image/*"
        capture="environment"
        onChange={handleCapture}
        ref={elementRef}
        style={{ opacity: 0, zIindex: 99 }}
        data-testid="capture-upload-document-hidden-input"
        aria-hidden="true"
      />
      <Page buttons={footerButtons} forceFillViewPort onSelectLanguage={onSelectLanguage}>
        {isWaiting && <LoadingSpinner heading={localizedString('waitingForApproval')} />}
        <IdSelectionContent
          idType={idType}
          onShowCaptureDigitalIdScreen={() => setShowCaptureDigitalIdScreen(true)}
          onShowAcceptableDocsClick={() => setAcceptableDocs(true)}
          isLoading={isUploading || isLoading || isWaiting}
          progressBar={progressBar}
        />
      </Page>
    </div>
  );
};

Capture.propTypes = {
  onNextStep: PropTypes.func,
  onGoBack: PropTypes.func,
  flowType: PropTypes.string,
  frontParams: PropTypes.object,
  selectedDiffId: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  isFlowV2DiffId: PropTypes.bool,
  captureVisa: PropTypes.bool,
  resetEditedFields: PropTypes.func,
  resetExtractedInfo: PropTypes.func,
  resetConfirmedInfo: PropTypes.func,
  setFrontIDParams: PropTypes.func,
  verify: PropTypes.bool,
  setDocId: PropTypes.func,
  onSelectLanguage: PropTypes.func,
  engine4Config: PropTypes.object,
  ipCountryCode: PropTypes.string,
  setExtractedInfo: PropTypes.func,
  setShouldDisplayFullName: PropTypes.func,
  // eslint-disable-next-line react/no-unused-prop-types
  onExit: PropTypes.func, // for withGeolocation
  // eslint-disable-next-line react/no-unused-prop-types
  onGeoLocation: PropTypes.func, // for withGeolocation
  isCameraEnabled: PropTypes.bool,
  checkCameraPermission: PropTypes.func
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withGeolocation,
  withCameraPermission,
  withUseFullNameContextHook
)(Capture);

function mapStateToProps({ capture, appConfig, ipInfo }) {
  return {
    frontParams: capture.frontParams,
    engine4Config: appConfig.engine4 ? appConfig.engine4.FLOW_V2 : null,
    ipCountryCode: ipInfo.ipCountryCode
  };
}

/**
 * Map the dispatch function of the store to the component's props
 * @param  {Function} dispatch The dispatch function
 * @return {Object}
 */
function mapDispatchToProps(dispatch) {
  return {
    setExtractedInfo: (data) => dispatch(InfoAction.setExtractedInfo(data)),
    resetExtractedInfo: () => dispatch(InfoAction.resetExtractedInfo()),
    resetConfirmedInfo: () => dispatch(InfoAction.resetConfirmedInfo()),
    resetEditedFields: () => dispatch(EditedAction.setEditedFields([])),
    setFrontIDParams: (data) => dispatch(CaptureAction.setFrontIDParams(data))
  };
}
